import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue'
import ChatInterface from '../components/ChatInterface.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/chat',
    name: 'ChatInterface',
    component: ChatInterface,
  },
  // ... more routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

