<template>
    <div class="home">
      <h1>Welcome to Virtual Man</h1>
      <p>Try our new chatbot</p>
      <router-link to="/chat">Start Chatting</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage'
  }
  </script>
  
  <style>
  .home {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  