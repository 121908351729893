<template>
  <div id="app">
    <header>
      <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/chat">Chat</router-link>
        <!-- Add more navigation links as needed -->
      </nav>
    </header>
    <main>
      <router-view/>
    </main>
    <footer>
      <p>© 2023 Vman AI Chat</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* Global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app {
  text-align: center;
}

nav {
  padding: 1em;
  background-color: #f5f5f5;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
}

footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f5f5;
}
</style>
