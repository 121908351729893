<template>
    <div class="chat-container">
      <div class="messages">
        <!-- Messages will be displayed here -->
        <p>This is where chat messages will appear.</p>
      </div>
      <div class="message-input">
        <input type="text" v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type a message...">
        <button @click="sendMessage">Send</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ChatInterface',
    data() {
      return {
        newMessage: ''
      }
    },
    methods: {
      sendMessage() {
        if (this.newMessage.trim() === '') return;
        console.log("Sending message:", this.newMessage); // Replace with actual send logic
        this.newMessage = ''; // Clear input field after sending
      }
    }
  }
  </script>
  
  <style>
  .chat-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .messages {
    height: 400px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 10px;
    overflow-y: auto;
  }
  
  .message-input {
    display: flex;
  }
  
  .message-input input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
  }
  
  .message-input button {
    padding: 10px;
  }
  </style>
  